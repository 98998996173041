<template>
    <div class="video-container">
        <div class="video-card" v-for="(item, index) in videoList" :key="index">
            <div class="video-content">
                <video-plyr :videoUrl="item.vUrl"></video-plyr>
            </div>
            <div class="video-title">{{ item.title }}</div>
        </div>
    </div>
</template>

<script>
import { getVedioHotList } from "@/service";
import videoPlyr from "./components/plyr.vue";
export default {
    components: {
        videoPlyr,
    },
    data() {
        return {
            videoList: [],
        };
    },
    mounted() {
        this.getVedioHotList();
    },
    methods: {
        async getVedioHotList() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
            };
            const { data } = await getVedioHotList(params);
            this.videoList = data.list;
        },
    },
};
</script>

<style lang="scss" scoped>
.video-container {
    padding: 40px 36px;
    .video-card {
        width: 100%;
        height: auto;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
        margin-bottom: 30px;
        .video-content {
            width: 100%;
            height: 406px;
        }
        .video-title {
            width: 100%;
            height: 75px;
            display: flex;
            align-items: center;
            padding-left: 20px;
            font-size: 24px;
            font-weight: 500;
        }
    }
}
</style>